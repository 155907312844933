import { useRouter } from 'next/router'
import PageLayout from '@/components/Layouts/PageLayout'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'

export default function Custom404() {
  const router = useRouter()

  useEffect(() => {
    mixpanel.init('b53e4087bdeef3afa147f7eb9e37140d')
    mixpanel.track('404 Error', {
      Route: router.asPath,
    })
  }, [router.asPath])

  return (
    <PageLayout title="Error 404">
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-6xl mt-20">404 - Page Not Found</h1>
        <p className="mt-10 text-3xl">
          The page you are looking for does not exist
        </p>
      </div>
    </PageLayout>
  )
}
